import React from "react";
import Logo from "./images/logos/Cheugy Logo 200x200 Transparent.png";
import Twitter from "./images/twitter.svg";
import Youtube from "./images/youtube2.svg";
import Telegram from "./images/telegram.svg";
import Instagram from "./images/instagram.svg";
import Facebook from "./images/facebook.svg";
import { Link as LinkR } from "react-scroll";
import { ConnectButton } from "./Connect_button";

const token_name = "Cheugy Coin";
const token_address = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

class Header_top extends React.Component {
  render() {
    return (
      <div className="header_top">
        <div className="logo">
          <a href="https://cheugycoin.com/">
            <embed src={Logo} alt="brand logo" style={{ width: "70px" }} />
            <span className="logo-topleft">{token_name}</span>
          </a>
        </div>
        <nav className="header-nav">
          <ul className="list list--inline">
            <li className="list__item text text--large text--medium">
              <a
                href="https://drive.google.com/file/d/1oSvkXsiYcD8ohy_AcvFcvA5_edpy8ecT/view"
                target="_blank"
                className="blank_link"
              >
                Blackpaper
              </a>
            </li>
            <LinkR to="features" spy={true} smooth={true}>
              <li className="list__item text text--large text--medium">
                Tokenomics
              </li>
            </LinkR>
            <LinkR to="roadmap" spy={true} smooth={true}>
              <li className="list__item text text--large text--medium">
                Roadmap
              </li>
            </LinkR>
            <li className="list__item text text--large text--medium">
              <a
                href="https://drive.google.com/file/d/1f5yfSiwjLRkkYwGp06wHRPwAMX5lDaOH/view"
                target="_blank"
                className="blank_link"
              >
                Audit
              </a>
            </li>
            <li className="list__item text text--large text--medium">
              <a
                href="https://drive.google.com/file/d/1QukrrwVedi0fWMFW5VDzPkb47nR8EiRJ/view"
                target="_blank"
                className="blank_link"
              >
                KYC
              </a>
            </li>
            <LinkR to="trade-securely" spy={true} smooth={true}>
              <li className="list__item text text--large text--medium">Buy</li>
            </LinkR>
          </ul>
          <a
            href="https://twitter.com/cheugy_coin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <form>
              <button
                className="btn btn--link text text--small text--medium"
                formaction="https://twitter.com/cheugy_coin"
              >
                <img className="discord" alt="Twitter" src={Twitter}></img>
              </button>
            </form>
          </a>
          <a
            href="https://www.youtube.com/@cheugycoin240"
            target="_blank"
            rel="noopener noreferrer"
          >
            <form>
              <button
                className="btn btn--link text text--small text--medium"
                formaction="https://www.youtube.com/@cheugycoin240"
              >
                <img className="discord" alt="Youtube" src={Youtube}></img>
              </button>
            </form>
          </a>
          <a
            href="https://www.facebook.com/people/Cheugy-Coin/100086595649252/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <form>
              <button
                className="btn btn--link text text--small text--medium"
                formaction="https://www.facebook.com/people/Cheugy-Coin/100086595649252/"
              >
                <img className="discord" alt="Facebook" src={Facebook}></img>
              </button>
            </form>
          </a>
          <a
            href="https://t.me/+kErA3gX8_ig1ZWZh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <form>
              <button
                className="btn btn--link text text--small text--medium"
                formaction="https://t.me/+kErA3gX8_ig1ZWZh"
              >
                <img className="discord" alt="Telegram" src={Telegram}></img>
              </button>
            </form>
          </a>
          <a
            href=" https://www.instagram.com/cheugycoin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <form>
              <button
                className="btn btn--link text text--small text--medium"
                formaction=" https://www.instagram.com/cheugycoin/"
              >
                <img className="discord" alt="Instagram" src={Instagram}></img>
              </button>
            </form>
          </a>
          <a
            href={
              "https://pancakeswap.finance/swap?outputCurrency=0x14B96dA234C5202d0256E2Ee64dde036dCcFD7e4"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn--accent text--small text--medium btn-top-right">
              Swap
            </button>
          </a>
          <span className="vertical-line" />
          <ConnectButton />
          <form>
            <button
              className="btn btn--link text text--small text--medium"
              formaction="https://twitter.com/cheugy_coin"
            >
              <img className="discord2" alt="Twitter" src={Twitter}></img>
            </button>
          </form>
          <form>
            <button
              className="btn btn--link text text--small text--medium"
              formaction="https://www.youtube.com/@cheugycoin240"
            >
              <img className="discord2" alt="Youtube" src={Youtube}></img>
            </button>
          </form>
          <form>
            <button
              className="btn btn--link text text--small text--medium"
              formaction="https://t.me/+kErA3gX8_ig1ZWZh"
            >
              <img className="discord2" alt="Telegram" src={Telegram}></img>
            </button>
          </form>
          <form>
            <button
              className="btn btn--link text text--small text--medium"
              formaction="https://www.instagram.com/cheugycoin/"
            >
              <img className="discord2" alt="Instagram" src={Instagram}></img>
            </button>
          </form>
          <form>
            <button
              className="btn btn--link text text--small text--medium"
              formaction="https://www.facebook.com/people/Cheugy-Coin/100086595649252/"
            >
              <img className="discord2" alt="Facebook" src={Facebook}></img>
            </button>
          </form>
        </nav>
      </div>
    );
  }
}

export default Header_top;
