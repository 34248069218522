import React from "react";
import Logo from "./images/logos/Cheugy Logo 200x200 Transparent.png";
import KYC from "./images/logos/KYC Verified.png";
import Coinsniper from "./images/logos/coinsniper.png";
import CoinsGods from "./images/logos/CoinsGods.png";
import Coinhunter from "./images/logos/Coinhunter.png";
import Coinlens from "./images/logos/Coinlens.png";
import Gemfiner from "./images/logos/Gemfinder.png";

const token_name = "Cheugy Coin";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer_logo">
          <div className="footer-logo-info">
            <div className="footer_logo_coin">
              <a href="https://coinsniper.net/coin/41066">
                <img src={Coinsniper} />
              </a>
            </div>
          </div>
          <div className="footer-logo-info">
            <div className="footer_logo_coin">
              <a href=" https://coinsgods.com/addcoin">
                <img src={CoinsGods} />
              </a>
            </div>
          </div>
          <div className="footer-logo-info">
            <div className="footer_logo_coin">
              <a href="https://coinhunt.cc/coin/635b44d8c7c537d4c0088b2f">
                <img src={Coinhunter} />
              </a>
            </div>
          </div>
          <div className="footer-logo-info">
            <div className="footer_logo_coin">
              <a href="https://coinlens.net/detail.php?id=6375">
                <img src={Coinlens} />
              </a>
            </div>
          </div>
          <div className="footer-logo-info">
            <div className="footer_logo_coin">
              <a href="https://gemfinder.cc/gem/11750">
                <img src={Gemfiner} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__top">
          <div className="footer__top__logo">
            <a href="https://cheugycoin.com/">
              <img src={Logo} alt="brand logo" />
              <span className="text text--medium">{token_name}</span>
            </a>
          </div>
          <div className="footer__top__logo">
            <a
              href="https://drive.google.com/file/d/1QukrrwVedi0fWMFW5VDzPkb47nR8EiRJ/view"
              target="_blank"
            >
              <img src={KYC} alt="brand logo" style={{ width: "100px" }} />
              <span className="text text--medium">KYC Verified</span>
            </a>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom__copyright text text--small text--regular">
            ©2022 {token_name}. All rights reserved.
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
