import React from "react";
import coin from "./images/insert_image_here.png";
import { Link as LinkR } from "react-scroll";
import Typical from "react-typical";

const token_name = "Cheugy Coin";
const token = "GENZ";
const token_address = "0x14B96dA234C5202d0256E2Ee64dde036dCcFD7e4";

class Header_body extends React.Component {
  render() {
    return (
      <div className="header_body">
        <div className="grid_choose grid-gap--small">
          <div className="value-proposition">
            <embed src={coin} className="token_logo" />
            <h1 className="value-proposition__title">
              Cheugy Coin is Going To The MOON
            </h1>
            <p className="value-proposition__text text text--small text--regular">
              Wish you got into{" "}
              <Typical
                className="typical"
                steps={[
                  "SHIB",
                  1500,
                  "DOGE",
                  1500,
                  "BTC",
                  1500,
                  "ETH",
                  1500,
                  "BNB",
                  1500,
                  "SOL",
                  1500,
                  "ADA",
                  1500,
                  "AVAX",
                  1500,
                  "MATIC",
                  1500,
                  "LINK",
                  1500,
                ]}
                loop={Infinity}
                wrapper="p"
              />
              early?
            </p>
            <p>
              {" "}
              Well this is your chance! {token_name} (<b>{token}</b>) is here.
            </p>
            <div className="badge-container-price">
              <span className="badge-hold badge--dark-hold text--small">
                Address:
              </span>
              <span className="text text--small text--regular text--white badge-hold2">
                {token_address}
              </span>
            </div>
            <br />
            <div className="badge-container-price">
              <span className="badge-hold badge--dark-hold text--small">
                Total Supply:
              </span>
              <span className="text text--small text--regular text--white badge-hold2">
                1000000000000
              </span>
            </div>
            <br />
            <div className="badge-container-price">
              <span className="badge-hold badge--dark-hold text--small">
                Market Cap:
              </span>
              <span className="text text--small text--regular text--white badge-hold2">
                $723.9973
              </span>
            </div>
            <br />
            <div className="badge-container-price">
              <span className="badge-hold badge--dark-hold text--small">
                Price:
              </span>
              <span className="text text--small text--regular text--white badge-hold2">
                $0.000000000834
              </span>
            </div>
            <br />
            <div style={{ display: "flex" }}>
              <LinkR to="trade-securely" spy={true} smooth={true}>
                <button className="value-proposition__button btn btn--flex btn--accent btn-buy">
                  <span className="text--standart text--medium text-bold">
                    Buy {token}
                  </span>
                </button>
              </LinkR>
              <LinkR
                to="cheugycoinlink"
                spy={true}
                smooth={true}
                style={{ marginLeft: "3%" }}
              >
                <button className="value-proposition__button btn btn--flex btn--accent btn-buy">
                  <span className="text--standart text--medium text-bold">
                    Cheugy coin link
                  </span>
                </button>
              </LinkR>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header_body;
