import React from "react";
import coin from "./images/coin/coin.png";
// import { TwitterFollowButton } from "react-twitter-embed";

class Team extends React.Component {
  render() {
    return (
      <div className="team" id="cheugycoinlink">
        <h2 className="banner__title team2">Cheugy Coin Listing</h2>
        <div className="me">
          <div className="me-info">
            <h3>Pancakeswap</h3>
            <div className="me-pic">
              <a href="https://pancakeswap.finance/swap?outputCurrency=0x14B96dA234C5202d0256E2Ee64dde036dCcFD7e4">
                <img alt="" src={coin} />
              </a>
            </div>
          </div>
          <div className="me-info">
            <h3>Tokpie</h3>
            <div className="me-pic">
              <a href="https://tokpie.io/tokens/cheugy-coin">
                <img alt="" src={coin} />
              </a>
            </div>
          </div>
          <div className="me-info">
            <h3>Mdex</h3>
            <div className="me-pic">
              <a href="https://bsc.mdex.me/#/swap?lang=en?outputCurrency=0x15ebee566b57ed4011e3e5fa06a911d67040f9ed">
                <img alt="" src={coin} />
              </a>
            </div>
          </div>
          <div className="me-info">
            <h3>BSC Swap</h3>
            <div className="me-pic">
              <a href="https://trade.bscswap.com/#/swap?outputCurrency=0x15ebee566b57ed4011e3e5fa06a911d67040f9ed">
                <img alt="" src={coin} />
              </a>
            </div>
          </div>
          <div className="me-info">
            <h3>Any Swap</h3>
            <div className="me-pic">
              <a href="https://bsc.anyswap.exchange/swap?outputCurrency=0x15ebee566b57ed4011e3e5fa06a911d67040f9ed#/router">
                <img alt="" src={coin} />
              </a>
            </div>
          </div>
          <div className="me-info">
            <h3>BSC Station</h3>
            <div className="me-pic">
              <a href="https://bscstation.finance/#/swap?outputCurrency=0x15ebee566b57ed4011e3e5fa06a911d67040f9ed ">
                <img alt="" src={coin} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
